.mobile_buttons {
    margin: 0;
    box-sizing: border-box;
    background: #102434;
    padding: 1rem 1rem;

    ul {
        display: flex;
        justify-content: space-between;
        padding: 0;
        align-items: center;
        align-self: center;
        margin: 0;

        li {
            list-style: none;

            .mob_but {
                width: 2rem;
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 48rem) {
    .mobile_buttons {
        display: none;
    }
}