.board {
    padding: 1rem;
    // margin: 0 auto;

    button {
        margin: 2rem 0;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        border: 1px solid #707070;
        background-color: #fff;
        cursor: pointer;
        font-family: "Fira Sans";
    }

    .charts {
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        img {
            padding-bottom: 1rem;
        }
    }
}

.timeline {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;

    .header {
        display: flex;
        justify-content: start;
    }

    .cirkel {
        color: white;
        background-color: black;
        border-radius: 50%;
        padding: 1rem;
    }

    .hamburger {
        padding-left: 80%;
        cursor: pointer;
    }

    span {
        align-self: center;
    }

    .timeline {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    .img {
        width: 2rem;
    }

}

@media (min-width:48rem) {
    .graphic {
        padding-bottom: 1rem;
        max-width: 11rem;
        width: 100%;
    }

    button:hover {
        background-color: black;
        color: white;
    }

    .board {
        max-width: 60%;
        margin: auto;
    }

    .timeline {
        display: none;
    }
}