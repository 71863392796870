.btnlogin {
    background-color: #6B3C00;
    border-radius: 1.5rem;
    padding: 1rem 1rem;
    width: 100%;
    font-size: 1.5rem;
    border: none;
    font-family: "Fira Sans";

    .btnLoginLink {
        color: white;
        text-decoration: none;
    }

    
}

@media (min-width: 64rem) {
    .btnlogin:hover {
        background-color: #472b06;
    }
}