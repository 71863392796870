.contact {
    margin: auto;
    padding: 1rem;

    h1 {
        margin-top: 2rem;
        font-family: "Fira Sans";
        text-align: center;
        font-size: 2rem;
        margin-top: 5rem;
        margin-bottom: 4rem;
    }

    .logo__black {
        margin-top: 5rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    p {
        font-family: "Fira Sans";
    }

}

.formik {

    // width: 60%;
    max-width: 100%;
    margin: 0 auto;
    font-family: "Fira Sans";

    .name {
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1.5rem;


    }

    label {
        display: flex;
        flex-direction: column;
        font-family: "Fira Sans";
    }

    input {
        border: 1px solid #BECCEA;
        border-radius: 0.5rem;
        font-family: "Fira Sans";
    }

}



@media (min-width: 48rem) {
    .contact {
        max-width: 60%;
        margin: 0 auto;
    }


    .input_twice {
        display: flex;
        justify-content: space-between;
        // width: 100%;
    }

    .name {
        width: 100%;
    }

    .logo {
        display: none;
    }

    .profileform {
        margin-top: 5rem;
    }
}

@media (min-width: 64rem) {


    h1 {
        font-size: 2.5rem;
        align-self: center;
    }

    h4 {
        font-size: 1.5rem;
    }

    .forlink {
        font-size: 1.2rem;
    }

    .privacy {
        font-size: 1.2rem;
    }
}