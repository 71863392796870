.btncontact {
    border: 1px solid #13293D;
    color: #13293D;
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 1rem 1rem;
    width: 100%;
    font-size: 1.5rem;
    margin-top: 2rem;
    font-family: bold;
    font-family: "Fira Sans";
}

@media (min-width: 48rem) {
    .btncontact {
        width: 25%;

    }
}

@media (min-width: 64rem) {

    .btncontact:hover {
        background-color: #13293D;
        color: #fff;
    }

}