.privacy {
    width: 60%;
    margin: 0 auto;
    align-items: center;

    h1 {
        margin-top: 2rem;
        text-align: center;
        font-size: 2rem;
        margin-top: 5rem;
        margin-bottom: 4rem;
        font-family: "Fira Sans";
    }

    h4 {
        font-size: 1.1rem;
        font-family: "Fira Sans";
    }

    p {
        font-family: "Fira Sans";
    }

    .faqs {
        width: 100%;
        // max-width: 768px;
        margin: 0 auto;
        padding: 15px;

        .faq.open .faqquestion {
            margin-bottom: 15px;
        }

        .faq.open .faqquestion::after {
            transform: translateY(-50%) rotate(180deg);
        }

        .faq.open .faqanswer {
            max-height: 1000px;
            opacity: 1;
        }

    }


}