.list {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;


    h1 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        align-self: center;
        font-family: "Fira Sans";
    }

    p {
        margin: 0;
        padding: 0;
        align-self: center;
        font-family: "Fira Sans";
    }

    .taskname {
        align-self: center;
        padding-left: 0.5rem;
    }

    .taskbtn {
        margin: 2rem 0;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        border: 1px solid #707070;
        background-color: #fff;
        cursor: pointer;
    }

    .tasks {


        .tasksList {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
            margin: 0;
            max-width: 100%;
            padding: 0;
        }

        .tasks__items {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
            margin: 0;
            max-width: 100%;

            .tasksList__item {
                display: flex;
                flex-direction: column;
                list-style-type: none;
                margin: 0;
                list-style: none;
                padding: 0;

                li {
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media (min-width:48rem) {

    .list {
        max-width: 30rem;
        margin: 0 auto;

        .header {
            display: none;
        }

        .taskbtn:hover {
            color: white;
            background-color: black;
        }
    }

}


@media (min-width:90rem) {
    .list {
        max-width: 70rem;
        margin: 0 auto;
    }
}