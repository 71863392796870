.timeline {
    text-align: center;
    padding: 1rem;

    h1 {
        font-family: "Fira Sans";
    }
}

.inputfields {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 20rem;
    font-family: "Fira Sans";
}

input {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
}


.hamburger {
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: space-between;
}

.cirkel {
    color: white;
    background-color: black;
    border-radius: 50%;
    padding: 1rem;
}

@media (min-width: 48rem) {
    input {
        width: 50%;
        margin-bottom: 1rem;
        padding: 0.5rem 0;
        border-radius: 0.5rem;
    }

    .inputfields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 40rem;
        margin: 0 auto;
    }

    .timeline {
        max-width: 30rem;
        margin: 0 auto;
    }
}


@media (min-width:48rem) {
    .header {
        display: none;
    }
}

@media (min-width:64rem) {
    .timeline {
        max-width: 50rem;
        margin: 0 auto;
    }
}

@media (min-width:90rem) {
    .timeline {
        max-width: 70rem;
        margin: 0 auto;
    }
}