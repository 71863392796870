.collab {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;


    h1 {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        align-self: center;
        font-family: "Fira Sans";
    }

    p {
        margin: 0;
        padding: 0;
        align-self: center;
        font-family: "Fira Sans";
    }

    .taskname {
        align-self: center;
        padding-left: 0.5rem;
    }

    .hamburger {
        padding-left: 50%;
        cursor: pointer;
    }

    .team {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h2 {
        font-family: "Fira Sans";
    }

    span {
        align-self: center;
        font-family: "Fira Sans";
    }

    .collab_in {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }

    img {
        width: 2rem;
    }

    button {
        margin: 2rem 0;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        border: 1px solid #707070;
        background-color: #fff;
        cursor: pointer;
        font-family: "Fira Sans";
    }

    @media (min-width: 48rem) {
        .header {
            display: none;
        }

        button:hover {
            background-color: black;
            color: white;
        }
    }

}

@media (min-width: 48rem) {
    .collab {
        max-width: 40%;
    }
}

@media (min-width: 64rem) {}