.signincontainer {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;

    .logo__black {
        display: flex;
        height: 5rem;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    h1 {
        padding-left: 1rem;
    }


    .googleLogin {
        border: 1px solid #BECCEA;
        margin-bottom: 4rem;
        justify-content: center;
        background-color: #fff;
        border-radius: 0.5rem;

        .image {
            border: 1px solid black;
            width: 2rem;
            height: 2rem;
        }

        display: flex;
    }

    .name {
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1.5rem;

        .error {
            font-size: 0.7rem;
            color: rgb(122, 12, 22);
            margin-bottom: 10px;

        }
    }

    .google_text {
        padding-left: 1rem;
    }

    label {
        display: flex;
        flex-direction: column;
    }

    input {
        border: 1px solid #BECCEA;
        border-radius: 0.5rem;

    }

    h2 {
        margin-bottom: 0.5rem;
        padding: 0;
        font-family: 'Fira Sans', sans-serif;
        font-size: 1rem;
    }

    .links {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }

    .forlink {
        padding-top: 1rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .privacy {
        text-align: center;
    }

}




@media (min-width: 48rem) {
    .signincontainer {
        max-width: 40rem;
        margin: 0 auto;
    }
}

@media (min-width: 64rem) {
    h1 {
        font-size: 2.5rem;
        align-self: center;
    }

    .logo__black {
        height: 7rem;
    }

    .google_text {
        font-size: 1.5rem;
    }

    .google_logo {
        width: 3rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .links {
        font-size: 1.2rem;
    }

    .forlink {
        font-size: 1.2rem;
    }

    .privacy {
        font-size: 1.2rem;
    }
}