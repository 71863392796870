.header {
    box-sizing: border-box;
    margin: 0;

    nav {

        border-bottom: 1px solid grey;
        padding: 1rem;


        .navigation {
            list-style-type: none;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 0;
            margin: 0 auto;
            max-width: 70%;
    
            .links {
                margin-right: 1.25rem;
                font-size: 1.25rem;
                color: #f1f1f1;
                cursor: pointer;
            
                a {
                    color: black;
                    text-decoration: none;
                    text-align: center;
                    align-items: center;
                    align-self: center;
                }
            }
    
            .searchBalk {
    
                display: flex;

                .nav_form {
                    padding-right: 5rem;
                }
            
                .search_nav {
                    border-radius: 1rem;
                    border: 1px solid #707070;
                    padding: 0.5rem 1rem;
                    padding-left: 2.5rem;
                    cursor: pointer;
                    background-image: url('https://image.flaticon.com/icons/png/512/709/709592.png');
                    background-position: 1rem 0.5rem;
                    background-size: 1rem;
                    background-repeat: no-repeat;
                    width: 5rem;
                    margin: 0;
                }
            
                .avatar {
                    width: 2rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }
}

.btn {
    display: none;
    position: absolute;
    right: 0.6rem;
    top: 0.4rem;
    padding: 0.3rem;
    color: #000;
    font-size: 1.1rem;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    header {

        nav {

            .navigation {
                flex-direction: column;
                height: auto;

                .links {
                    width: 100%;
                    text-align: center;
                    margin-right: 0px;
                    padding: 1.25rem 0;
                }

                .links:nth-child(1) {
                    margin-top: 3.1rem;
                }

                .search_nav {
                    width: 100%;
                    margin: 0;
                }
            }

            .btn {
                display: block;
            }
        }
    }
}