.profile {
    margin: 0 auto;
    padding: 1rem;

    h1 {
        margin-top: 2rem;
        text-align: center;
        font-size: 2rem;
        margin-top: 5rem;
        margin-bottom: 4rem;
        font-family: "Fira Sans";
    }

    .container__logo {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    p {
        font-family: "Fira Sans";
    }

    .btnsubmit {
        background-color: #6B3C00;
        border-radius: 1.5rem;
        padding: 1rem 1rem;
        width: 100%;
        font-size: 1.5rem;
        border: none;
        font-family: "Fira Sans";
    }

}

.logo__black {
    margin-top: 5rem;
    margin-bottom: 4rem;
}

h1 {
    margin-top: 2rem;
    font-size: 2rem;
}

.name {
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 1.5rem;

}

label {
    display: flex;
    font-family: "Fira Sans";
    flex-direction: column;
}

input {
    border: 1px solid #BECCEA;
    border-radius: 0.5rem;
    font-family: "Fira Sans";
}

h2 {
    margin-bottom: 0.5rem;
    padding: 0;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1rem;
}

@media (min-width: 48rem) {
    .profile {
        max-width: 60%;
        margin: 0 auto;
    }

    .name {
        width: 100%;
    }

    .input_twice {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .logo {
        height: 15rem;
    }

    .profileform {
        margin-top: 5rem;
    }
}

@media (min-width: 64rem) {


    h1 {
        font-size: 2.5rem;
        align-self: center;
    }

    h4 {
        font-size: 1.5rem;
    }

    .forlink {
        font-size: 1.2rem;
    }

    .privacy {
        font-size: 1.2rem;
    }
}