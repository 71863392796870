.btncreate {
    background-color: #6B3C00;
    color: white;
    border-radius: 1.5rem;
    padding: 1rem 1rem;
    width: 100%;
    font-size: 1.5rem;
    border: none;
    margin-top: 2rem;
    font-family: "Fira Sans";

    .btncreateLink {
        color: white;
        text-decoration: none;
    }
}

@media (min-width: 64rem) {
    .btncreate:hover {
        background-color: #472b06;
    }
}