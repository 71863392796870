.about {
    margin: 0 auto;
    padding: 1rem;

    h1 {
        margin-top: 2rem;
        text-align: center;
        font-size: 2rem;
        margin-top: 5rem;
        margin-bottom: 4rem;
        font-family: "Fira Sans";
    }

    .text {
        line-height: 1.3rem;
        padding-bottom: 2rem;
        font-family: "Fira Sans";

    }

    h2 {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 3rem;
        font-family: "Fira Sans";
    }

    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-self: center;
        text-align: center;
        justify-content: space-between;

    }

    li {
        list-style: none;
    }
}

@media (min-width: 48rem) {
    .about {
        max-width: 60%;
        margin: auto;
    }

    h1 {
        text-decoration: underline;
    }

    ul {
        max-width: 100%;
        margin: auto;
    }
}