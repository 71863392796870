.faq {
    margin: 15px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-family: "Fira Sans";

    .faqquestion {
        position: relative;
        font-size: 20px;
        padding-right: 80px;
        font-family: "Fira Sans";
        // transition: all 0.4s;
    }

    .faqquestion::after {
        font-family: "Fira Sans";
        content: '';
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-image: url('../assets/down-chevron.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.4s ease-out;
    }

    .faqanswer {
        opacity: 0;
        font-family: "Fira Sans";
        max-height: 0;
        overflow-y: hidden;
        transition: all 0.4s ease-out;
    }
}