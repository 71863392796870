.boardCard {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;

    .titleCard {
        font-size: 1rem;
        font-family: "Fira Sans";
        margin: 0;
        padding: 0;
        align-self: center;
        margin-bottom: 2rem;
        font-family: "Fira Sans";
    }

    .allcards {
        text-align: center;
    }

    .container__items{

        padding: 0;

        .container__Cards {
            color: black;
            box-sizing: border-box;
            border: 2px solid black;
            width: 100%;
            max-width: 20rem;
            border-radius: 1.5rem;
            padding: 0 2rem;
            margin-bottom: 2rem;
            list-style-type: none;
    
            .logo {
                width: 2rem;
                padding-right: 2rem;
                justify-content: start;
            }
        }

    }

    .hamburger {
        cursor: pointer;
    }

    .profile__resp {
        display: flex;
    }

}


@media (min-width:48rem) {
    .boardCard {
        max-width: 40%;

        .header {
            display: none;
        }

        .allcards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}