.sideBar {
    display: none;
}

@media (min-width: 48rem) {
    .sideBar {
        background-color: #13293D;
        display: block;
        width: 8rem;
        height: 100%;
        position: fixed;
        top: 0;
        padding-left: 0px;

        .logo {
            width: 19.5rem;
            height: 11rem;
            position: relative;
            left: -6rem;
            top: -2rem;
        }

        ul {
            color: white;
            list-style-type: none;
            height: 40%;
            padding-left: 1rem;

            li {
                padding-top: 2rem;
                font-family: "Fira Sans";
            }

            a {
                color: #fff;
                text-decoration: none;
                font-family: "Fira Sans";

            }

            .info {
                padding-top: 20rem;
            }
        }
    }
}