.registercontainer {
    margin: 0 auto;
    align-items: center;
    padding: 1rem;

    .logo__black {
        display: flex;
        height: 5rem;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    h1 {
        padding-left: 1rem;
    }

    .name {
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 1.5rem;

        .error {
            font-size: 0.7rem;
            color: rgb(122, 12, 22);
            margin-bottom: 10px;
        }

    }

    label {
        display: flex;
        flex-direction: column;
    }

    input {
        border: 1px solid #BECCEA;
        border-radius: 0.5rem;
    }

    h2 {
        margin-bottom: 0.5rem;
        padding: 0;
        font-family: 'Fira Sans', sans-serif;
        font-size: 1rem;
    }

    .forlink {
        padding-top: 1rem;
        padding-bottom: 2rem;
        text-align: center;
    }

    .privacy {
        text-align: center;
    }

}

@media (min-width: 48rem) {
    .registercontainer {
        max-width: 40rem;
        margin: 0 auto;
    }
}

@media (min-width: 64rem) {
    h1 {
        font-size: 2.5rem;
        align-self: center;
    }

    .logo__black {
        height: 7rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    .forlink {
        font-size: 1.2rem;
    }

    .privacy {
        font-size: 1.2rem;
    }
}